/*!
 * FullCalendar v2.6.1 Stylesheet
 * Docs & License: https://fullcalendar.io/
 * (c) 2015 Adam Shaw
 */
@import url(https://fonts.googleapis.com/css?family=Quicksand);

.fc {
	direction: ltr;
	text-align: left;
}

.fc-rtl {
	text-align: right;
}

body .fc {
	/* extra precedence to overcome jqui */
	font-size: 1em;
	color: gray;
	font-family: 'Quicksand', sans-serif;

	* {
		color: gray;
	}
}

.fc-col-header {
	background-color: #ff9800;

	* {
		text-decoration: none;
		color: #fff !important;
	}
}

.fc-daygrid-event-dot {
	margin: 0 4px;
	box-sizing: content-box;
	width: 0;
	height: 0;
	border: 4px solid #ff9800;
	border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid
		var(--fc-event-border-color, #ff9800);
	border-radius: 4px;
	border-radius: calc(var(--fc-daygrid-event-dot-width, 8px) / 2);
}

.fc-h-event {
	display: block;
	border: 1px solid #ff9800;
	border: 1px solid var(--fc-event-border-color, #ff9800);
	background-color: #ff9800;
	background-color: var(--fc-event-bg-color, #ff9800);
	color: #fff !important;

	* {
		color: #fff !important;
	}
}

.fc .fc-button-primary {
	color: #fff;
	color: var(--fc-button-text-color, #fff);
	background-color: #ff9800;
	background-color: var(--fc-button-bg-color, #ff9800);
	border-color: #ff9800;
	border-color: var(--fc-button-border-color, #ff9800);
}

.fc .fc-button-primary:disabled {
	color: #fff;
	color: var(--fc-button-text-color, #fff);
	background-color: #2c3e50;
	background-color: var(--fc-button-bg-color, #2c3e50);
	border-color: #2c3e50;
	border-color: var(--fc-button-border-color, #2c3e50);
}

.fc {
	.fc-prev-button.fc-button.fc-button-primary
		> span.fc-icon.fc-icon-chevron-left {
		color: #fff !important;
	}

	.fc-next-button.fc-button.fc-button-primary
		> span.fc-icon.fc-icon-chevron-right {
		color: #fff !important;
	}

	.fc-daygrid-day.fc-day-today {
		background-color: var(
			--fc-today-bg-color,
			rgba(255, 220, 40, 0.15)
		) !important;
	}
}
